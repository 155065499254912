#videoComponent {
  position: relative; }
  #videoComponent video {
    width: 100%; }
  #videoComponent #video-desk-home {
    display: none; }

/* Content Horizontal Alignment PD Attribute */
.GVB-alignment-centro,
.GVB-alignment-izquierda,
.GVB-alignment-derecha {
  text-align: center;
  width: 100%;
  right: 0; }

.GVB-alignment-centro,
.GVB-alignment-izquierda {
  left: 0; }

.GVB-alignment-vertical-arriba {
  top: 35%; }

.GVB-alignment-vertical-centro {
  top: 65%; }

.GVB-alignment-vertical-abajo {
  top: 85%; }

/* END Content Horizontal Alignment PD Attribute */
@media screen and (min-width: 768px) {
  #videoComponent #video-desk-home {
    display: block; }
  #videoComponent #video-desk-mov {
    display: none; }
  #videoComponent .divVideo {
    width: 100%; } }
